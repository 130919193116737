// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-action-center-js": () => import("./../../../src/templates/action-center.js" /* webpackChunkName: "component---src-templates-action-center-js" */),
  "component---src-templates-bio-js": () => import("./../../../src/templates/bio.js" /* webpackChunkName: "component---src-templates-bio-js" */),
  "component---src-templates-endorsements-landing-js": () => import("./../../../src/templates/endorsements-landing.js" /* webpackChunkName: "component---src-templates-endorsements-landing-js" */),
  "component---src-templates-events-landing-js": () => import("./../../../src/templates/events-landing.js" /* webpackChunkName: "component---src-templates-events-landing-js" */),
  "component---src-templates-general-content-js": () => import("./../../../src/templates/general-content.js" /* webpackChunkName: "component---src-templates-general-content-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-issue-js": () => import("./../../../src/templates/issue.js" /* webpackChunkName: "component---src-templates-issue-js" */),
  "component---src-templates-issues-landing-js": () => import("./../../../src/templates/issues-landing.js" /* webpackChunkName: "component---src-templates-issues-landing-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-item-landing-js": () => import("./../../../src/templates/news-item-landing.js" /* webpackChunkName: "component---src-templates-news-item-landing-js" */),
  "component---src-templates-plan-chapter-js": () => import("./../../../src/templates/plan-chapter.js" /* webpackChunkName: "component---src-templates-plan-chapter-js" */),
  "component---src-templates-plan-js": () => import("./../../../src/templates/plan.js" /* webpackChunkName: "component---src-templates-plan-js" */)
}

